@media screen and (min-width: 700px) {

  * {
    font-family: arial;
  }

  a {
    text-decoration: none;
  }

  a:link {
    color: white;
  }
  
  a:visited {
    color: white;
  }

  body {
      background-color: black;
      color: white;
      flex-direction: column;
      background-image: url(./background.jpeg);
      background-size: cover;
    }

  .links-cont {
      display: flex;
      justify-content: space-around;
      align-items: center;
      text-align: center;
      height: 100px;
      font-size: 30px;
      border-bottom: 0.5px solid lightslategray;
      overflow: auto;
  }

  .body-cont {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      text-align: center;
      height: 725px;
      overflow: auto;
  }

  .copyright-cont {
      text-align: center;
      font-size: 20px;
      border-top: 0.5px solid lightslategray;
      padding-top: 30px;
      height: 30px;
      overflow: auto;
  }

  .personal-link {
      padding: 10px;
      border-radius: 10px;
      cursor: pointer;
  }

  .personal-link:hover {
      text-decoration: underline;
  }

  .personal-image {
    height: 300px;
    width: 350px;
    border-radius: 50%;
    border: 5px solid white;
  }

  .hello {
    font-size: 40px;
  }

  .contact-link {
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
  }

  .contact-link:hover {
    text-decoration: underline;
  }

  .link-desc {
    font-size: 20px;
  }

  .body-block-3 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .contact-me {
    font-size: 25px;
  }

}


















@media screen and (max-width: 700px) {

  * {
    font-family: arial;
  }

  a {
    text-decoration: none;
  }

  a:link {
    color: white;
  }
  
  a:visited {
    color: white;
  }

  body {
      background-color: black;
      color: white;
      flex-direction: column;
      background-image: url(./background.jpeg);
      background-size: cover;
    }

  .links-cont {
      display: flex;
      justify-content: space-around;
      align-items: baseline;
      text-align: center;
      height: 100px;
      font-size: 20px;
      border-bottom: 0.5px solid lightslategray;
      overflow: auto;
  }

  .body-cont {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      height: 725px;
      overflow: auto;
  }

  .copyright-cont {
      text-align: center;
      font-size: 15px;
      border-top: 0.5px solid lightslategray;
      padding-top: 30px;
      height: 30px;
      overflow: auto;
  }

  .personal-link {
      padding: 10px;
      border-radius: 10px;
      cursor: pointer;
  }

  .personal-link:hover {
      text-decoration: underline;
  }

  .personal-image {
    height: 300px;
    width: 350px;
    border-radius: 50%;
    border: 5px solid white;
  }

  .hello {
    font-size: 30px;
  }

  .contact-link {
    padding: 5px;
    border-radius: 10px;
    font-size: 15px;
    cursor: pointer;
  }

  .contact-link:hover {
    text-decoration: underline;
  }

  .link-desc {
    font-size: 15px;
  }

  .desc {
    font-size: 15px;
  }

  .body-block-3 {
    font-size: 20px;
  }

  .links {
    display: flex;
    flex-direction: column;
  }

}